import React from 'react';
import './css/app.css';
import { Helmet } from 'react-helmet';

function App() {
    return (
        <div>
            <Helmet>
                <script async src={`https://www.googletagmanager.com/gtag/js?id=G-FL6HFRFRKF`}></script>
                <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-FL6HFRFRKF', { send_page_view: false });
                `}
                </script>
            </Helmet>
            <header className="header">
                <div className="logo">
                    <svg
                        id="logo_svg"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="841.89px"
                        preserveAspectRatio="none"
                        height="595.28px"
                        viewBox="0 0 841.89 595.28"
                    >
                        <g id="&#x30EC;&#x30A4;&#x30E4;&#x30FC;_1">
                        </g>
                        <g id="&#x30EC;&#x30A4;&#x30E4;&#x30FC;7">
                            <g>
                                <g>
                                    <path d="M360.13,262.451c-8.233-8.216-18.455-12.327-30.66-12.327c-7.552,0-14.544,1.878-21.025,5.657
                                        c-6.455,3.79-11.58,9.014-15.331,15.679c-3.739,6.653-5.606,13.906-5.606,21.718c0,11.737,4.117,21.721,12.326,29.977
                                        c8.239,8.266,18.328,12.389,30.295,12.389c11.806,0,21.818-4.155,30.017-12.455c8.217-8.288,12.327-18.383,12.327-30.297
                                        C372.474,280.762,368.364,270.656,360.13,262.451z M348.563,325.437c-5.735,3.302-12,4.965-18.754,4.965
                                        c-10.111,0-18.76-3.602-25.923-10.794c-7.17-7.158-10.771-15.99-10.771-26.418c0-7.059,1.623-13.447,4.87-19.181
                                        c3.229-5.729,7.74-10.289,13.512-13.674c5.768-3.369,11.869-5.075,18.312-5.075c6.56,0,12.754,1.706,18.599,5.075
                                        c5.856,3.385,10.383,7.9,13.607,13.53c3.219,5.628,4.826,11.94,4.826,18.892c0,7.015-1.606,13.377-4.826,19.117
                                        C358.791,317.613,354.318,322.124,348.563,325.437z"/>
                                    <path d="M535.325,262.341c-8.233-8.228-18.444-12.333-30.666-12.333c-7.535,0-14.538,1.884-20.999,5.651
                                        c-6.487,3.779-11.593,8.993-15.344,15.68c-3.745,6.669-5.628,13.906-5.628,21.729c0,11.704,4.116,21.71,12.347,29.966
                                        c8.228,8.254,18.332,12.388,30.289,12.388c11.813,0,21.813-4.156,30.023-12.444c8.227-8.288,12.315-18.395,12.315-30.303
                                        C547.663,280.657,543.553,270.541,535.325,262.341z M523.773,325.305c-5.751,3.313-12,4.963-18.76,4.963
                                        c-10.133,0-18.776-3.589-25.935-10.781c-7.188-7.192-10.763-15.992-10.763-26.419c0-7.059,1.625-13.457,4.849-19.192
                                        c3.25-5.729,7.759-10.277,13.532-13.651c5.762-3.396,11.867-5.07,18.316-5.07c6.55,0,12.748,1.673,18.604,5.07
                                        c5.851,3.375,10.383,7.883,13.597,13.513c3.224,5.635,4.83,11.938,4.83,18.898c0,7.008-1.606,13.377-4.83,19.127
                                        C534.001,317.481,529.501,322.013,523.773,325.305z"/>
                                    <path d="M109.934,335.243h-8.26l-33.724-46.53l-17.203,16.812v29.719h-6.167V250.03h6.167v46.849L98.66,250.03h8.565
                                        l-34.799,34.095L109.934,335.243z"/>
                                    <path d="M156.928,336.806c-5.042,0-9.352-0.531-12.921-1.584c-3.584-1.075-6.57-2.505-8.924-4.333
                                        c-2.372-1.807-4.256-3.935-5.629-6.36c-1.391-2.427-2.449-5.031-3.169-7.79c-0.715-2.747-1.158-5.596-1.346-8.565
                                        c-0.177-2.958-0.271-5.842-0.271-8.64V250.03h6.16v49.265c0,6.508,0.582,11.78,1.751,15.837c1.147,4.078,2.859,7.259,5.092,9.519
                                        c2.227,2.304,4.969,3.855,8.188,4.687c3.213,0.843,6.897,1.253,11.07,1.253c4.21,0,7.945-0.41,11.213-1.253
                                        c3.274-0.831,5.995-2.394,8.188-4.709c2.188-2.294,3.851-5.475,4.964-9.529c1.136-4.033,1.701-9.296,1.701-15.804V250.03h6.172
                                        v49.503c0,5.859-0.487,11.122-1.467,15.731c-0.976,4.609-2.671,8.521-5.098,11.701c-2.41,3.203-5.696,5.629-9.867,7.324
                                        C168.579,335.975,163.299,336.806,156.928,336.806z"/>
                                    <path d="M278.986,335.243H272.2l-8.405-22.616c-0.831-2.183-1.744-4.243-2.748-6.161c-0.997-1.938-2.177-3.58-3.513-5.027
                                        c-1.34-1.435-2.87-2.561-4.587-3.385c-1.728-0.82-3.745-1.251-6.055-1.251h-29.546v38.44h-6.178V250.03h38.034
                                        c3.546,0,6.992,0.343,10.305,0.986c3.318,0.647,6.272,1.817,8.865,3.457c2.615,1.662,4.675,3.967,6.215,6.893
                                        c1.542,2.936,2.316,6.681,2.316,11.258c0,2.836-0.343,5.489-1.035,7.967c-0.699,2.459-1.752,4.676-3.175,6.604
                                        c-1.402,1.951-3.208,3.601-5.386,4.986c-2.188,1.385-4.759,2.371-7.69,2.993c1.597,1.24,2.908,2.437,3.945,3.605
                                        c1.025,1.125,1.906,2.35,2.654,3.629c0.748,1.308,1.424,2.728,2.006,4.292c0.592,1.55,1.273,3.347,2.043,5.352L278.986,335.243z
                                        M217.346,290.591h29.314c4.161,0,7.75-0.31,10.725-0.914c2.971-0.643,5.44-1.673,7.369-3.092
                                        c1.923-1.441,3.369-3.291,4.278-5.562c0.93-2.283,1.396-5.081,1.396-8.399c0-5.862-1.845-10.068-5.519-12.577
                                        c-3.684-2.521-9.163-3.778-16.477-3.778h-31.086V290.591z"/>
                                    <path d="M456.456,335.243H448.2l-33.719-46.53l-17.219,16.812v29.719h-6.167V250.03h6.167v46.849l47.924-46.849h8.565
                                        l-34.794,34.095L456.456,335.243z"/>
                                    <path d="M632.686,291.986c0,28.852-14.212,43.257-42.649,43.257h-25.553v-85.119h25.553c14.238,0,24.92,3.463,32.011,10.389
                                        C629.141,267.438,632.686,277.919,632.686,291.986z M626.138,291.986c0-5.589-0.544-10.604-1.651-15.019
                                        c-1.12-4.41-3.07-8.155-5.824-11.23c-2.797-3.098-6.51-5.452-11.151-7.053c-4.666-1.607-10.539-2.416-17.641-2.416h-19.22v72.759
                                        h19.22c7.047,0,12.897-0.82,17.552-2.482c4.664-1.673,8.376-4.09,11.147-7.28c2.786-3.191,4.725-7.069,5.872-11.635
                                        C625.583,303.073,626.138,297.85,626.138,291.986z"/>
                                    <path d="M659.943,335.243h-6.172V250.03h6.172V335.243z"/>
                                    <path d="M734.473,335.243H684.71V250.03h6.176v78.997h43.587V335.243z"/>
                                    <path d="M812.331,335.243h-61.038V250.03h58.95v6.238h-52.772v31.513h49.471v6.229h-49.471v35.018h54.86V335.243z"/>
                                    <path d="M323.016,292.823c0,12.713,5.014,23.04,6.976,23.04c1.91,0,6.942-10.327,6.942-23.04c0-12.709-4.815-23.03-6.942-23.03
                                        C327.614,269.793,323.016,280.114,323.016,292.823z"/>
                                    <path d="M507.75,292.718c0,12.696-1.973,23.023-2.57,23.023c-0.576,0-2.658-10.327-2.658-23.023
                                        c0-12.726,1.827-23.042,2.658-23.042C505.856,269.676,507.75,279.992,507.75,292.718z"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </header>
        </div>
    );
}

export default App;
